<template>
  <v-container>
    <!-- Header -->
    <header class="custom-header mb-6">
      <h4>දැන්වීම්</h4>
    </header>

    <v-data-table
      :footer-props="$store.state.dataTableFooterProps"
      :headers="headers"
      :items="advertisements"
      :loading="loadingAdTable"
      :options.sync="options"
      :server-items-length="total"
      class="custom-shadow custom-radius"
    >
      <template v-slot:top>
        <v-toolbar class="custom-radius" color="#fafafa" dense flat>
          <v-toolbar-title class="subtitle-1">දැන්වීම්</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog max-width="500" persistent v-model="dialog">
            <template v-slot:activator="{ on }">
              <v-btn color="accent" depressed fab small v-on="on">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>නව දැන්වීම</v-card-title>
              <v-card-text>
                <v-form lazy-validation ref="form">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        :rules="[requiredRule]"
                        dense
                        label="දුරකතන අංකය"
                        type="number"
                        v-model="phone"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        :rules="email ? [emailRule] : []"
                        dense
                        label="ඊ-මේල් ලිපිනය"
                        type="email"
                        v-model="email"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        dense
                        label="වෙබ් අඩවිය"
                        placeholder="https://example.com"
                        v-model="website"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select
                        :items="packages"
                        :rules="[requiredRule]"
                        dense
                        item-text="price"
                        item-value="id"
                        label="පැකේජය"
                        v-model="packageId"
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-file-input
                        :rules="[!!file || 'අවශ්‍යයි']"
                        @change="selectAdvertisement"
                        accept="image/*"
                        dense
                        label="ත‌ෝරන්න"
                        prepend-icon
                        prepend-inner-icon="mdi-camera"
                        show-size
                      ></v-file-input>
                    </v-col>
                  </v-row>
                  <v-img :src="fileURL" contain max-width="550"></v-img>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="dialog = false" color="error" text>අවලංගු කරන්න</v-btn>
                <v-btn @click="createAdvertisement" color="accent" text>එකතු කරන්න</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template slot="no-data">ලබා ගත හැකි දත්ත නැත</template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-end">
          <v-switch
            @change="changeAdVisibility(item)"
            class="mt-1"
            color="accent"
            hide-details
            v-model="item.show"
          ></v-switch>
          <v-btn @click="viewAd(item.file)" class="mx-2" color="secondary" icon>
            <v-icon>mdi-eye</v-icon>
          </v-btn>
          <v-btn @click="deleteAdvertisement(item)" color="error" icon>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <!-- Package table -->
    <v-data-table
      :footer-props="$store.state.dataTableFooterProps"
      :headers="packageTableheaders"
      :items="packages"
      :loading="loadingPackageTable"
      class="custom-shadow custom-radius mt-10"
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar class="custom-radius" color="#fafafa" dense flat>
          <v-toolbar-title class="subtitle-1">පැකේජ</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog max-width="390" persistent v-model="packageDialog">
            <template v-slot:activator="{ on }">
              <v-btn color="accent" depressed fab small v-on="on">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>නව පැකේජයක් එක් කරන්න</v-card-title>
              <v-card-text class="py-3">
                <v-row>
                  <v-col cols="12">
                    <v-text-field dense label="මිල" type="number" v-model="pkg.price"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      label="කාල සීමාව (මාස)"
                      type="number"
                      v-model="pkg.periodInMonths"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="closePackageDialog" color="error" text>අවලංගු කරන්න</v-btn>
                <v-btn @click="addPackage" color="success" text>සුරකින්න</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template slot="no-data">ලබා ගත හැකි දත්ත නැත</template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-end">
          <v-btn @click="editPackage(item)" class="mr-2" color="secondary" icon>
            <v-icon>mdi-square-edit-outline</v-icon>
          </v-btn>
          <v-btn @click="deletePackage(item)" color="error" icon>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <!-- Image dialog -->
    <v-dialog max-width="560" v-model="imageDialog">
      <v-sheet class="d-flex" min-height="315">
        <v-row align-content="center" justify="center" no-gutters v-if="!advertisementImage">
          <v-progress-circular color="accent" indeterminate size="64"></v-progress-circular>
        </v-row>
        <v-img :src="advertisementImage" v-else></v-img>
      </v-sheet>
    </v-dialog>

    <ConfirmDialog ref="confirm" />
  </v-container>
</template>

<script>
import { ApiService } from "../../services/api.service";
import { mapMutations } from "vuex";
import { SHOW_ALERT, CHANGE_LOADING_STATE } from "../../store/mutation-types";
import ConfirmDialog from "../../components/ConfirmDialog";

export default {
  components: {
    ConfirmDialog
  },

  data: () => ({
    requiredRule: v => !!v || 'අවශ්‍යයි',
    emailRule: v => /.+@.+\..+/.test(v) || 'ඊ-මේල් ලිපිනය වලංගු විය යුතුය',
    dialog: false,
    imageDialog: false,
    packageDialog: false,
    advertisementImage: "",
    headers: [
      { text: 'දුරකතන අංකය', sortable: false, value: 'phone', },
      { text: 'ඊ-මේල් ලිපිනය', sortable: false, value: 'email' },
      { text: 'සාදන ලද දිනය', value: 'created_at' },
      { text: 'ක්‍රියා', value: 'actions', sortable: false, align: 'end' },
    ],
    packageTableheaders: [
      { text: 'මිල (රුපියල්)', sortable: false, value: 'price' },
      { text: 'කාල සීමාව (මාස)', sortable: false, value: 'periodInMonths' },
      { text: 'ක්‍රියා', value: 'actions', sortable: false, align: 'end' },
    ],
    advertisements: [],
    packages: [],
    loadingAdTable: false,
    loadingPackageTable: false,
    options: {},
    total: 0,
    // New advertisement data
    phone: '',
    email: '',
    website: '',
    packageId: '',
    file: null,
    fileURL: '',
    // New package data
    pkg: {
      price: 0,
      periodInMonths: ''
    },
    defaulPackage: {
      price: 0,
      periodInMonths: '',
    },
    editedPackageIndex: -1
  }),

  async mounted() {
    this.$store.commit(CHANGE_LOADING_STATE, { active: true });
    this.fetchAdvertisements();
    await this.fetchPackages();
    this.$store.commit(CHANGE_LOADING_STATE, { active: false });
  },

  watch: {
    options: {
      handler: function () {
        this.fetchAdvertisements();
      },
      deep: true,
    }
  },

  methods: {
    ...mapMutations({ showAlert: SHOW_ALERT }),

    selectAdvertisement: function (file) {
      if (file) {
        this.file = file;
        this.fileURL = URL.createObjectURL(file);
      }
    },

    // Get advertisements
    fetchAdvertisements: async function () {
      this.loadingAdTable = true;

      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const order = sortDesc[0] ? 'desc' : 'asc';
      const pageOptions = itemsPerPage != -1 ? { perPage: itemsPerPage, page: page } : {};
      const sortOptions = sortBy.length != 0 ? { sortBy: sortBy[0], order: order } : {};

      try {
        const url = "advertisements";
        const params = { params: { ...pageOptions, ...sortOptions } };
        const response = await ApiService.get(url, params);
        this.advertisements = response.data.data;
        this.total = response.data.meta.total;
      } catch (error) {
        console.log(error);
      }
      this.loadingAdTable = false;
    },

    // Create an advertisement
    createAdvertisement: async function () {
      if (this.$refs.form.validate()) {
        this.dialog = false;
        this.loadingAdTable = true;
        try {
          const fd = new FormData();
          fd.append("file", this.file);
          fd.append("phone", this.phone);
          fd.append("email", this.email);
          fd.append("website", this.website);
          fd.append("packageId", this.packageId);

          const url = "files/upload/advertisement";
          const response = await ApiService.post(url, fd);
          this.advertisements.push(response.data);
          this.showAlert({ message: 'දැන්වීම එකතු කරන ලදි', type: "success" });
        } catch (error) {
          console.log(error);
        }
        this.loadingAdTable = false;
      }
    },

    // View an advertisement
    viewAd: async function (file) {
      this.advertisementImage = '';
      this.imageDialog = true;
      try {
        const url = "files/download";
        const params = { params: { file: file } };
        const response = await ApiService.get(url, params);
        this.advertisementImage = process.env.VUE_APP_BASE_URL + response.data;
      } catch (error) {
        console.log(error);
      }
      this.loadingAdViewBtn = false;
    },

    // Show/hide advertisement
    changeAdVisibility: async function (item) {
      this.loadingAdTable = true;
      const messageText = item.show ? "සක්‍රිය" : "අක්‍රිය";
      try {
        const url = "advertisements/change-visibility";
        const data = { id: item.id, value: item.show };
        const response = await ApiService.put(url, data);
        this.showAlert({ message: `දැන්වීම ${messageText} කරන ලදි`, type: "info" });
      } catch (error) {
        item.show = false;
      }
      this.loadingAdTable = false;
    },

    // Delete an advertisement
    deleteAdvertisement: async function (item) {
      if (await this.$refs.confirm.open('දැන්වීම ඉවත් කරන්න', 'දැන්වීම ඉවත් කිරීමට අවශ්‍යද?', { color: 'warning', persistent: true })) {
        this.loadingAdTable = true;
        try {
          const url = `advertisements/delete/${item.id}`;
          const response = await ApiService.delete(url);
          const index = this.advertisements.indexOf(item);
          this.advertisements.splice(index, 1);
          this.showAlert({ message: "දැන්වීම ඉවත් කරන ලදි", type: "success" });
        } catch (error) {
          console.log(error);
        }
        this.loadingAdTable = false;
      }
    },

    // Fetch packages
    fetchPackages: async function () {
      this.loadingPackageTable = true;
      try {
        const url = 'ad-packages';
        const response = await ApiService.get(url);
        this.packages = response.data;
      } catch (error) {
        console.log(error);
      }
      this.loadingPackageTable = false;
    },

    // Add or update a package
    addPackage: async function () {
      this.packageDialog = false;
      this.loadingPackageTable = true;
      try {
        if (this.editedPackageIndex > -1) { // Edit
          const url = `ad-packages/${this.pkg.id}`;
          const response = await ApiService.put(url, this.pkg);
          Object.assign(this.packages[this.editedPackageIndex], response.data);
          this.showAlert({ message: "පැක‌ේජය යාවත්කාලීන කරන ලදි", type: "success" });
        } else { // Create
          const url = 'ad-packages';
          const response = await ApiService.post(url, this.pkg);
          this.packages.push(response.data);
          this.showAlert({ message: "නව පැක‌ේජය එකතුඑකතු කරන ලදි", type: "success" });
        }
      } catch (error) {
        console.log(error);
      }
      this.loadingPackageTable = false;
    },

    // Edit a package
    editPackage: function (pkg) {
      this.editedPackageIndex = this.packages.indexOf(pkg);
      this.pkg = Object.assign({}, pkg);
      this.packageDialog = true;
    },

    // Delete a package
    deletePackage: async function (pkg) {
      if (await this.$refs.confirm.open('පැක‌ේජය ඉවත් කරන්න', 'පැක‌ේජය ඉවත් කිරීමට අවශ්‍යද?', { color: 'warning', persistent: true })) {
        this.loadingPackageTable = true;
        try {
          const url = `ad-packages/${pkg.id}`;
          const response = await ApiService.delete(url);
          const index = this.packages.indexOf(pkg);
          this.packages.splice(index, 1);
          this.showAlert({ message: "පැක‌ේජය ඉවත් කරන ලදි", type: "success" });
        } catch (error) {
          if (error.response.data.code == 23000) {
            this.showAlert({ message: "මෙම පැක‌ේජය ඉවත් කිරීමට න‌ොහැක. පැක‌ේජය භාවිතා කරන සාමාජිකයන් ඇත.", type: "error" });
          }
        }
        this.loadingPackageTable = false;
      }
    },

    // Close package dialog
    closePackageDialog: function () {
      this.packageDialog = false
      this.$nextTick(() => {
        this.pkg = Object.assign({}, this.defaultPackage)
        this.editedPackageIndex = -1;
      })
    },
  }
}
</script>